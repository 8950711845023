import Vue from 'vue'
import App from './App.vue'
import { Button, Toast, Field, CellGroup, ActionSheet } from 'vant';
import 'vant/lib/button/style';
import router from './router'
import store from './store'

Vue.config.productionTip = false
Vue.use(Button);
Vue.use(Toast);
Vue.use(Field);
Vue.use(CellGroup);
Vue.use(ActionSheet);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
