<template>
  <div class="map-container" :style="{height: screenHeight + 'px'}">
    <div id="g-map"></div>
    <div class="map-search" :style="{top: safeArea.top + 'px'}">
      <div @click="onClose">Back</div>
      <input v-model="address" class="search-input" type="text" />
      <img class="search-icon" @click="getLocation" src="../../assets/search.png" />
    </div>
    <div class="map-location" v-if="loading">
      <img class="map-icon map-loading" src="../../assets/map_loading.png" alt="">
    </div>
    <div class="map-location" @click="onAmapLocation" v-else>
      <img class="map-icon" src="../../assets/map_location.png" alt="">
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Loader } from '@googlemaps/js-api-loader'
let gMapContext
let gMapTimer
let geocoder
let gNearSearch
export default {
  props: {
    latlng: {
      type: Object,
      default: function () {
        return null
      }
    }
  },
  watch: {
    latlng (val) {
      if (val && gMapContext) {
        const center = { lat: val.lat, lng: val.lng }
        this.latitude = val.lat
        this.longitude = val.lng
        gMapContext.setCenter(center)
      }
    }
  },
  computed: {
    ...mapState(['safeArea']),
    location () {
      return {
        id: 0,
        latitude: this.latitude,
        longitude: this.longitude,
        iconPath: '../assets/imgs/location.png',
        width: 20,
        height: 30
      }
    }
  },
  data () {
    return {
      screenHeight: 667,
      latitude: Number(process.env.VUE_APP_MAP_LAT),
      longitude: Number(process.env.VUE_APP_MAP_LNG),
      markets: [],
      address: '',
      loading: false,
      nearbyPlaces: []
    }
  },
  methods: {
    onClose () {
      console.log('关闭')
    },
    getLocation () {
      if (this.address) {
        geocoder.geocode({ 'address': this.address }, (results, status) => {
          if (status === 'OK') {
            console.log('geocode result', results)
            const result = results[0]
            const latlng = { lat: result.geometry.location.lat(), lng: result.geometry.location.lng() }
            gMapContext.setCenter(latlng)
            gMapContext.setZoom(17)
          } else {
            this.$toast.fail('Geocode was not successful for the following reason: ' + status)
          }
        })
      } else {
        this.$toast.fail('input error')
      }
    },
    getAddress (latlng) {
      // const latlng = { lat: this.latitude, lng: this.longitude }
      this.$emit('market', { latlng })
      geocoder.geocode({ 'location': latlng }, (results, status) => {
        if (status === 'OK') {
          console.log('geocode result', results)
          const result = results[0]
          this.$emit('market', { latlng, address: result.formatted_address })
        } else {
          // this.$emit('market', { latlng })
          this.$toast.fail('Geocode was not successful for the following reason: ' + status)
        }
      })
    },
    onMap (e) {
      this.latitude = e.latLng.lat()
      this.longitude = e.latLng.lng()
      const latlng = { lat: this.latitude, lng: this.longitude }
      this.getAddress(latlng)
    },
    async initMap () {
      if (document.getElementById('g-map')) {
        this.times = 0
        console.log('gmapcontext')
        // The location of Uluru
        const center = { lat: this.latitude, lng: this.longitude }
        // The map, centered at Uluru
        const loader = new Loader({
          apiKey: this.$route.query.apikey,
          version: "weekly"
        })
        loader.load().then(async () => {
          const { Map } = await window.google.maps.importLibrary("maps");

          gMapContext = new Map(document.getElementById("g-map"), {
            center: center,
            zoom: 17,
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: true,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            clickableIcons: false
          })
        })
        const {PlacesService} = await window.google.maps.importLibrary("places")
        gNearSearch = PlacesService
        this.gNearbySearch(center)
        geocoder = new window.google.maps.Geocoder()
        // gMapContext.setCenter(center)
        // 初始地点
        if (this.latlng) {
          const center = { lat: this.latlng.lat, lng: this.latlng.lng }
          this.latitude = this.latlng.lat
          this.longitude = this.latlng.lng
          gMapContext.setCenter(center)
        } else {
          this.onAmapLocation()
        }
        gMapContext.addListener('click', (event) => {
          console.log(event)
          this.onMap(event)
        })
        gMapContext.addListener('center_changed', () => {
          console.log('中心点改变===', gMapContext.getCenter().lat(), gMapContext.getCenter().lng())
          this.gNearbySearch({ lat: gMapContext.getCenter().lat(), lng: gMapContext.getCenter().lng() })
        })
      } else {
        this.times++
        console.log('gmaptimeout')
        gMapTimer = setTimeout(this.initMap, 500 * this.times)
      }
    },
    async gNearbySearch (latlng) {
      if (gNearSearch && gNearSearch.nearbySearch) {
        // 根据当前位置查询附近地点
        gNearSearch.nearbySearch({
          location: { lat: latlng.lat, lng: latlng.lng },
          radius: 10000, // 指定搜索半径
          type: 'locality,school,airport,library,bar,store,hospital,zoo,transit_station,tourist_attraction,supermarket' // 指定搜索类型，比如“restaurant”、“cafe”等
        }, (results) => {
          if (results) {
            this.nearbyPlaces = results;
            console.log('附近地点列表===', results)
          } else {
            console.log("No places found.");
          }
        });
      } else {
        const { PlacesService } = await window.google.maps.importLibrary("places")
        gNearSearch = new PlacesService(gMapContext);
        this.gNearbySearch(latlng)
      }
    },
    importMap () {
      if (!gMapContext && !window.google) {
        console.log('gmap import')
        this.createScript(`https://maps.googleapis.com/maps/api/js?key=${this.$route.query.apikey}&callback=initMap`)
      } else {
        console.log('gmap init')
        this.initMap()
      }
    },
    createScript (src) {
      let oscript = window.document.createElement('script')
      oscript.id = `g-map-script`
      oscript.src = src
      oscript.onerror = error => {
        console.log('google map load fail', error)
      }
      let head = window.document.head || window.document.getElementsByTagName('head')[0] || window.document.documentElement
      head.insertBefore(oscript, head.firstChild)
    },
    onAmapLocation () {
      const vm = this
      if (gMapContext) {
        if (window.api) {
          vm.loading = true
          window.api.startLocation(
            {
              accuracy: '100m',
              filter: 1,
              autoStop: true
            },
            (ret, err) => {
              vm.loading = false
              if (ret && ret.status) {
                const center = { lat: ret.latitude, lng: ret.longitude }
                vm.latitude = ret.latitude
                vm.longitude = ret.longitude
                gMapContext.setCenter(center)
                gMapContext.setZoom(17)
              } else {
                vm.$toast.fail(err.msg)
              }
            }
          )
        } else {
          vm.$toast.fail(this.$t('msg').env_failure)
        }
      } else {
        vm.$toast.text('等待地图加载')
      }
    }
  },
  created () {
    window.initMap = this.initMap
  },
  mounted () {
    this.initMap()
    this.screenHeight = window.innerHeight
  },
  destroyed () {
    console.log('google map destroyed')
    if (gMapContext) {
      gMapContext = null
    }
    if (gMapTimer) {
      clearTimeout(gMapTimer)
    }
  }
}
</script>

<style>
@keyframes loading {
  0%{transform:rotate(0deg);}
  25%{transform:rotate(90deg);}
  50%{transform:rotate(180deg);}
  75%{transform:rotate(270deg);}
  100%{transform:rotate(360deg);}
}
#g-map{
  height: 100%;
}
.map-search{
  padding: 10px 20px;
  position: fixed;
  top: 0;
  background: #ffffff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 24px;
}
.search-input {
  line-height: 40px;
  border: none;
  border-radius: 40px;
  background-color: #f6f6f6;
  padding: 0 20px;
  outline: none;
  margin: 0 20px;
  width: 50%;
}
.search-icon {
  width: 30px;
  height: 30px;
}
.map-location {
  position: fixed;
  right: 40px;
  bottom: 100px;
  width: 30px;
  height: 30px;
  padding: 10px;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0px 2px 16px 0px rgba(52, 125, 255, 0.4);
}
.map-icon {
  width: 100%;
  height: 100%;
}
.map-loading {
  animation: loading 1.5s linear infinite;
}
</style>
