<template>
  <div>
    <div>
      <a-map v-if="$route.query.mapType === 'amap'" ref="googleMap" @market="onMarket" @close="onClose" :latlng="latlng"></a-map>
      <google-map v-else ref="googleMap" @market="onMarket" @close="onClose" :latlng="latlng" />
    </div>
    <van-action-sheet v-model="infoVisible" @close="onInfoClose">
        <div slot="custom" class="location-info">
          <van-cell-group>
            <van-field v-model="locationInfo.latLng" label="Location：" placeholder="23.322,115.332" disabled />
            <van-field v-model="locationInfo.address" label="Address：" disabled />
          </van-cell-group>
          <div class="market-btns">
            <van-button plain type="default" @click="onClear">cancel</van-button>
            <van-button plain type="info" @click="onSubmit">confirm</van-button>
          </div>
        </div>
    </van-action-sheet>
  </div>
</template>

<script>
import GoogleMap from '../components/map/google-map.vue'
import AMap from '../components/map/a-map.vue'
import { mapState } from 'vuex'
export default {
  components: {
    GoogleMap,
    AMap
  },
  computed: {
    ...mapState(['mapType'])
  },
  watch: {
    locationInfo (val) {
      if (val) {
        setTimeout(() => {
          this.infoVisible = true
        }, 10)
      }
    }
  },
  data () {
    return {
      locationInfo: {},
      infoVisible: false,
      latlng: {}
    }
  },
  methods: {
    onMarket (data) {
      // let vm = this
      this.locationInfo = {
        latLng: `${data.latlng.lat},${data.latlng.lng}`,
        address: data.address
      }
      // setTimeout(() => {
      //   vm.onSubmit()
      // }, 100)
    },
    onInfoClose () {
      this.infoVisible = false
    },
    onClear () {
      if (this.$refs.googleMap) {
        this.$refs.googleMap.clearMap()
        this.locationInfo = {}
      }
    },
    onSubmit () {
      this.$emit('location', this.locationInfo)
    },
    onClose () {
      this.$emit('close')
    }
  },
  created () {
    let route = this.$route.query
    if (route.latlng) {
      let arr = route.latlng.split(',')
      this.latlng = {
        lat: Number(arr[0]),
        lng: Number(arr[1])
      }
    }
  }
}
</script>

<style>
.location-info{
  padding: 20px;
}
.market-btns{
  padding: 20px;
  display: flex;
}
</style>
