<template>
  <div class="map-container" :style="{height: screenHeight + 'px'}">
    <div id="a-map"></div>
    <div class="map-search" :style="{top: safeArea.top + 'px'}">
      <div @click="onClose">Back</div>
      <input v-model="address" class="search-input" type="text" />
      <img class="search-icon" @click="getLocation" src="../../assets/search.png" />
    </div>
    <div class="map-location" v-if="loading">
      <img class="map-icon map-loading" src="../../assets/map_loading.png" alt="">
    </div>
    <div class="map-location" @click="onAmapLocation" v-else>
      <img class="map-icon" src="../../assets/map_location.png" alt="">
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
let aMapContext
let aMapTimer
let geocoder
export default {
  props: {
    latlng: {
      type: Object,
      default: function () {
        return null
      }
    }
  },
  watch: {
    latlng (val) {
      if (val && aMapContext) {
        const center = [val.lng, val.lat]
        this.latitude = val.lat
        this.longitude = val.lng
        aMapContext.setCenter(center)
      }
    }
  },
  computed: {
    ...mapState(['safeArea']),
    location () {
      return {
        id: 0,
        latitude: this.latitude,
        longitude: this.longitude,
        iconPath: '../../assets/location.png',
        width: 20,
        height: 30
      }
    }
  },
  data () {
    return {
      screenHeight: 667,
      latitude: '',
      longitude: '',
      markets: [],
      address: '',
      loading: false
    }
  },
  methods: {
    onClose () {
      console.log('关闭')
    },
    getLocation () {
      if (this.address) {
        geocoder.getLocation(this.address, (status, result) => {
          if (status === 'complete' && result.info === 'OK') {
            console.log('geocode result', result)
            const location = result.geocodes[0].location
            aMapContext.setCenter(location)
            // aMapContext.setZoom(14)
          } else if (status === 'no_data') {
            this.$toast.fail('未识别搜索地址')
          } else {
            this.$toast.fail('Geocode was not successful for the following reason: ' + status)
          }
        })
      } else {
        this.$toast.fail('input error')
      }
    },
    getAddress (latlng) {
      // const latlng = { lat: this.latitude, lng: this.longitude }
      this.$emit('market', { latlng })
      geocoder.getAddress([latlng.lng, latlng.lat], (status, result) => {
        console.log('geocode result', status, result)
        if (status === 'complete' && result.info === 'OK') {
          console.log('geocode result', result)
          this.$emit('market', { latlng, address: result.regeocode.formattedAddress })
        } else {
          // this.$emit('market', { latlng })
          this.$toast.fail('Geocode was not successful for the following reason: ' + status)
        }
      })
    },
    onMap (e) {
      this.latitude = e.lat
      this.longitude = e.lng
      this.getAddress(e)
    },
    initMap () {
      if (document.getElementById('a-map')) {
        this.times = 0
        console.log('amapcontext')
        aMapContext = new window.AMap.Map('a-map', {
          zoom: 17,
          resizeEnable: true
        })
        // 初始地点
        if (this.latlng) {
          const center = [this.latlng.lng, this.latlng.lat]
          this.latitude = this.latlng.lat
          this.longitude = this.latlng.lng
          aMapContext.setCenter(center)
        } else {
          this.onAmapLocation()
        }
        aMapContext.plugin('AMap.Geocoder', () => {
          geocoder = new window.AMap.Geocoder()
        })
        aMapContext.on('click', (event) => {
          console.log(event)
          this.onMap(event.lnglat)
        })
      } else {
        this.times++
        console.log('gmaptimeout')
        aMapTimer = setTimeout(this.initMap, 500 * this.times)
      }
    },
    importMap () {
      if (!aMapContext && !window.AMap) {
        console.log('gmap import')
        this.createScript(`https://webapi.amap.com/maps?v=1.4.15&key=${this.$route.query.apikey}&callback=initMap`)
      } else {
        console.log('gmap init')
        this.initMap()
      }
    },
    createScript (src) {
      let oscript = window.document.createElement('script')
      oscript.charset = 'utf-8'
      oscript.src = src
      oscript.onerror = error => {
        console.log('map load fail', error)
      }
      let head = window.document.head || window.document.getElementsByTagName('head')[0] || window.document.documentElement
      head.insertBefore(oscript, head.firstChild)
    },
    onAmapLocation () {
      const vm = this
      if (aMapContext) {
        if (window.api) {
          const amapLocation = window.api.require('aMapLocation')
          vm.loading = true
          amapLocation.startLocation(
            {
              accuracy: 100,
              filter: 1,
              autoStop: true
            },
            (ret, err) => {
              vm.loading = false
              if (ret.status) {
                const center = [ret.longitude, ret.latitude]
                vm.latitude = ret.latitude
                vm.longitude = ret.longitude
                aMapContext.setCenter(center)
                aMapContext.setZoom(17)
              } else {
                vm.$toast.fail(err.msg)
              }
            }
          )
        } else {
          vm.$toast.fail(vm.$t('msg').env_failure)
        }
      } else {
        vm.$toast.text('等待地图加载')
      }
    }
  },
  created () {
    window.initMap = this.initMap
  },
  mounted () {
    this.importMap()
    this.screenHeight = window.innerHeight
  },
  destroyed () {
    console.log('google map destroyed')
    if (aMapContext) {
      aMapContext = null
    }
    if (aMapTimer) {
      clearTimeout(aMapTimer)
    }
  }
}
</script>

<style>
@keyframes loading {
  0%{transform:rotate(0deg);}
  25%{transform:rotate(90deg);}
  50%{transform:rotate(180deg);}
  75%{transform:rotate(270deg);}
  100%{transform:rotate(360deg);}
}
#a-map{
  height: 100%;
}
.map-search{
  padding: 10px 20px;
  position: fixed;
  top: 0;
  background: #ffffff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 24px;
}
.search-input {
  width: 50%;
  line-height: 40px;
  border: none;
  border-radius: 40px;
  background-color: #f6f6f6;
  padding: 0 20px;
  outline: none;
  margin: 0 20px;
}
.search-icon {
  width: 30px;
  height: 30px;
}
.map-location {
  position: fixed;
  right: 40px;
  bottom: 100px;
  width: 30px;
  height: 30px;
  padding: 10px;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0px 2px 16px 0px rgba(52, 125, 255, 0.4);
}
.map-icon {
  width: 100%;
  height: 100%;
}
.map-loading {
  animation: loading 1.5s linear infinite;
}
</style>
