import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    safeArea: JSON.parse(localStorage.getItem('safe_area')) || { top: 0, bottom: 0 }
  },
  getters: {
  },
  mutations: {
    safeArea (state, payload) {
      localStorage.setItem('safe_area', JSON.stringify(payload))
      state.safeArea = payload
    }
  },
  actions: {
  },
  modules: {
  }
})
